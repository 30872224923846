<template>
  <van-popup v-model="visibility" class="dialog-confirm">
    <div class="content">
      <div class="header">{{ title }}</div>
      <div class="message">
        <div class="text">一、适用范围</div>
        <div class="text">未订购与本产品有互斥关系产品，且符合产品办理条件的四川联通手机用户。</div>
        <div class="text">
          <span>温馨提示：因受用户套餐、号码使用状态、产品规则变化等影响，是否能订购该产品以办理当时系统提示为准。</span>
        </div>
        <div class="text">二、产品介绍</div>
        <div class="text">产品名称：“29元彩铃云盘权益N选2流量组合包”，<span>29元/月</span>，包含：</div>
        <div class="text">
          1、联通安全管家，包含（通话安全、短信安全、亲情守护三大功能）；<br>
          2、联通云盘乐享会员：会员特权/尊贵身份标识+云盘储存容量100G;<br>
          3、视频彩铃钻石会员，包含（视频彩铃+互联网热门权益N选2），权益产品种类因受市场变化和领取情况等因素影响，可能会随时调整更新、上线或下线，具体可领取权益请以领取页面展示为准。<br>
          4、产品包含8GB区域流量(四川省内使用)，订购副卡独享。可结转。区域流量优先国内流量。该产品包含流量及5G速率无法解除限速产品的限速阈值。<br>
          5、以上产品为组合销售，不可单独取消，单独订购
        </div>
        <div class="text">三、生效规则： </div>
        <div class="text"><span>订购后立即生效，如不取消，每月自动续订。会员权益领取机会当月有效，需每月领取，过期不补。</span></div>
        <div class="text">四、计费规则:</div>
        <div class="text"><span>连续包月:订购后立即扣费，如不取消，每月1号自动续订并从话费中扣取产品费用29元。</span></div>
        <div class="text">五、业务退订：</div>
        <div class="text"><span>如需退订请向中国联通客服热线、中国联通APP、营业厅等咨询办理。</span></div>
        <div class="text">六、退订规则：</div>
        <div class="text"><span>当月退订，次月生效，次月不再收取包月费。会员权益领取机会当月有效，需每月领取，过期不补。请在退订前完成权益领取。</span></div>
        <div class="text">七、使用说明:</div>
        <div class="text">
          （1）含8GB省内流量。流量共享规则：①智慧沃家共享版用户订购，仅限本人使用，他人不共享（此项规则在智慧沃家共享版用户可以办理该产品时适用，若受业务办理规则限制，智慧沃家共享版用户订购失败，则此项规则不适用）。②主副卡用户中主卡订购，副卡可共享包内流量。副卡订购仅限副卡使用。（规则②部分仅面对主卡是实卡的主副卡关系。所有智慧沃家用户订购，请参考共享规则①）<br>
          （2）安全管家：包含通话安全、短信安全和亲情守护三大功能。通话安全是对多种类型的骚扰电话、疑似诈骗电话提供来电提醒和拦截服务。短信安全是对国际短信进行风险提醒。亲情守护在移网侧是对被守护人的亲情号码的通话安全、手机上网安全向守护人进行提醒；在固网侧对接入家庭网关的终端可按时间、按应用进行上网管理。<br>
          （3）联通云盘100G乐享会员：会员特权/尊贵身份标识:乐享会员;空间容量:100G;创建家庭数:10,家庭成员数:20;大文件上传:1G回收站有效期:7天。<br>
          （4）视频彩铃钻石会员服务，可登录联通视频彩铃APP、联通视频彩铃小程序、中国联通APP视频彩铃专区等查看使用。包含的热门APP会员权益，点击订购通知短信链接，<a
            href="https://huodong.10155.com/h5/hdact4/diamondVip4_lottery/#/?chl=1f053fa95d57781b42df5a670d63dfb4">t.10155.com/psg</a>，登录后可领取权益。权益产品种类因受市场变化和领取情况等因素影响，可能会随时调整更新、上线或下线，具体可领取权益请以领取页面展示为准。<br>
          （5）5G基础服务包（5G上网服务（下行峰值300Mbps））。温馨提示：您办理5G包类资费，持5G终端可登录5G网络，享受更高的网速；持4G终端无法使用5G网络，但可使用产品流量；您可通过手厅APP5G专区查询本地区5G网络覆盖情况。<br>
          （6）权益内容：一刻talks会员月卡：下载“一刻talks”app,享会员福利，全站免费观看精品付费视频和完整版演讲局视频。2024年12月31日前订购用户赠送“一刻talks会员月卡”，“一刻talks会员月卡”赠送至2024年12月31日。
        </div>
        <div class="text">八、办理规则：</div>
        <div class="text">因受用户套餐、号码使用状态、产品规则变化等影响，是否能订购该产品以办理当时系统提示为准。</div>
        <div class="text">九、通过本页面下单成功不代表产品订购成功，订购成功以收到产品订购成功短信通知为准。</div>
        <div class="text">十、客服电话:4001189599（7天*24小时）</div>
        <div class="text">产品服务:联通在线信息科技有限公司</div>
        <div class="text">产品支持:浙江秋末信息科技有限公司</div>
      </div>
      <div class="foot" @click="close">确认</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-confirm {
    box-sizing: border-box;
    width: 642px;
    overflow-x: hidden;
    border-radius: 10px;

    .content {
      margin: 0 auto;
      width: 100%;
      height: 710px;
      overflow: hidden;
      font-size: 26px;

      .header {
        width: 100%;
        height: 100px;
        text-align: center;
        line-height: 100px;
      }

      .message {
        width: 100%;
        height: 500px;
        overflow-y: scroll;
        padding: 30px;
        box-sizing: border-box;

        .text {
          .title {
            font-weight: 700;
          }

          span {
            color: red;
          }
        }
      }

      .foot {
        width: 100%;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: red;
        font-size: 30px;
        border-top: 1px solid #dddddd;
      }
    }
  }
}
</style>
